import {Link} from 'gatsby'
import {format} from 'date-fns'
import React from 'react'
import {buildImageObj, getBookUrl, leadingZeros} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import '../styles/book-archive.css'

function BookArchive (props) {

  //console.log(props);

  return (

    <div className="book-archive-item width-12-12-m width-6-12">

        <div className="grid">

          <div className="width-1-12-m width-1-12 catalog-number">
            <p>{leadingZeros(props.catalogNumber)}</p>
          </div>

          <div className="width-4-12-m width-4-12">

            {props.mainImage && props.mainImage.asset && (
              <div className="image-holder">
                <Link to={getBookUrl(props.publishedAt, props.slug.current)}>

                <figure
                  style={{
                    backgroundImage: `url(${props.mainImage.asset.metadata.lqip})`,
                    paddingTop: `calc(100% / ${props.mainImage.asset.metadata.dimensions.aspectRatio})`
                   }}
                >
                  <img
                    src={imageUrlFor(buildImageObj(props.mainImage))
                      .width(600)
                      .auto('format')
                      .url()}
                    alt={props.mainImage.alt}
                    title={props.mainImage.alt}
                  />
                </figure>

                </Link>

              </div>
            )}

          </div>

          <div className="width-7-12-m width-7-12">

            <h4><Link to={getBookUrl(props.publishedAt, props.slug.current)}>{props.title}</Link></h4>

            <p class="author">{props.author}</p>

            <div className="info">
                
              <div>
                <p>Date:</p>

                {props.publisher && props.link && (
                  <p>Publisher:</p>
                )}
              </div>
              <div>
                <p>{format(new Date(props.publishedAt), 'MM.DD.YYYY')}</p>

                {props.publisher && props.link && (
                  <p><a aria-label={props.title} rel="noopener noreferrer" title={props.title} href={props.link} target="_blank">{props.publisher}</a></p>
                )}
              </div>

            </div>

          </div>



        </div>

    </div>
  )
}

export default BookArchive