import React from 'react'
import BookArchive from './book-archive'

import '../styles/book-archive-list.css'

function BookArchiveList (props) {
  return (
    <div className="book-archive-list wrapper">
      {props.title && <h3>{props.title}</h3>}
        
      <div className="grid book-archive-holder">
        {props.nodes &&
          props.nodes.map(node => (
              <BookArchive {...node} />
          ))}
      </div>
</div>
  )
}

BookArchiveList.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default BookArchiveList